import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import ContactForm from "src/components/shared/ContactForm"
import Typography from "src/components/shared/Typography"
import styles from "./amelie-geoffrey.styles.module.css"
import ArrowSeparator from "src/components/shared/ArrowSeparator"

import Section from "src/components/shared/Section"

const AboutPage = () => (
  <Layout bannerImage="lotus">
    <SEO title="Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
    <Inner>
      <section className={styles.Intro}>
        <Typography.h1>Qui sommes-nous</Typography.h1>
        <Typography.p primary></Typography.p>
      </section>
    </Inner>

    <ArrowSeparator to="about" />

    <Section id="about">
      <Typography.h1>Turtle Tonic</Typography.h1>
      <Typography.p primary></Typography.p>
    </Section>

    <div className={styles.ContactForm}>
      <Inner small id="contact">
        <ContactForm />
      </Inner>
    </div>
  </Layout>
)

export default AboutPage
